import React from 'react';

import Layout from '@common/Layout';
import { Container } from '@components/global';

const NotFoundPage = () => (
  <Layout>
    <Container>
      <h1>NOT FOUND</h1>
      <p>Sorry, must have left the barn doors open...</p>
    </Container>
  </Layout>
);

export default NotFoundPage;
